// Used to fade (disable inputs + css fade) the whole container on which this is used.
import { Controller } from "stimulus"

export default class extends Controller {
    static targets = [ "checkbox" ]

    connect() {
        if (!this.hasCheckboxTarget) return;

        this.element.querySelectorAll('input:not([type="checkbox"]), select').forEach((input) => {
            input.dataset.wasDisabledBeforeSelect = input.disabled;
        });

        this.change();
    }

    change() {
        // Ensure this only runs if the checkbox target exists
        if (this.hasCheckboxTarget && this.checkboxTarget.checked) {
            this.element.classList.add("screen-selected");
        } else {
            this.element.classList.remove("screen-selected");
        }
    }
}

