$(function () {
    const $screenSelectListing = $('.screen-select-listing');
    const $startInput = $('#start');
    const $stopInput = $('#stop');
    const $screenSelectSubmit = $('.screen-select-submit');
    const $campaignFile = $('#campaign_file_new_campaign');
    const $importCampaignButton = $('#import_campaign_button');
    const $ssfForm = $('#ssf');
    const locale = 'cs-CZ';


    function parseDate(dateStr) {
        const parts = dateStr.trim().split('.');
        if (parts.length !== 3) return null;
        const year = parseInt(parts[2], 10);
        const month = parseInt(parts[1], 10) - 1;
        const day = parseInt(parts[0], 10);
        const date = new Date(year, month, day);
        return isNaN(date.getTime()) ? null : date;
    }

    function formatDate(date) {
        if (!date) return '';
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const year = date.getFullYear();
        return `${day}.${month}.${year}`;
    }

    function debounce(func, wait) {
        let timeout;
        return function(...args) {
            const context = this;
            clearTimeout(timeout);
            timeout = setTimeout(() => func.apply(context, args), wait);
        };
    }

    function updatePostData() {
        const data = $ssfForm.serializeArray();
        const filteredData = [];
        for (let i = 0; i < data.length; i++) {
            if (data[i].name !== '_method') {
                filteredData.push(data[i]);
            }
        }
        $screenSelectListing.data('postData', filteredData);
    }

    function toggleImportButton() {
        const hasFile = $campaignFile.val() !== '';
        $importCampaignButton.prop('disabled', !hasFile);
    }

    toggleImportButton();

    $campaignFile.on('change', toggleImportButton);


    $startInput.on('change', function () {
        const value = this.value;
        $('.start').val(value);

        const startDate = parseDate(value);
        const stopDateStr = $stopInput.val();
        const stopDate = parseDate(stopDateStr);

        if (!stopDate || stopDate < startDate) {
            $stopInput.val(value);
            $('.stop').val(value);
        }

        updatePostData();
        $screenSelectListing.trigger('refresh');
    });

    if ($startInput.val()) {
        $('.start').val($startInput.val());
    }


    $stopInput.on('change', function () {
        const value = this.value;
        $('.stop').val(value);

        const startDateStr = $startInput.val();
        const startDate = parseDate(startDateStr);
        const stopDate = parseDate(value);

        if (!startDate || stopDate < startDate) {
            $startInput.val(value);
            $('.start').val(value);
        }

        updatePostData();
        $screenSelectListing.trigger('refresh');
    });

    if ($stopInput.val()) {
        $('.stop').val($stopInput.val());
    }

    $('#slots').on('change', function () {
        const value = $(this).val();
        $('.slots').val(value);
        updatePostData();
        updateAllAvailableSlotsCount();
        updateAllChosenSlotsCount();
        $screenSelectListing.trigger('refresh');
    });

    $('#discount').on('change', function () {
        const value = $(this).val();
        $('.discount').val(value);
        updatePostData();
    });

    function updateScreenCount() {
        // Cache selectors
        const $visibleListingItems = $('tbody .listing-item:not(.d-none)');
        const screenCount = $visibleListingItems.length;
        $('.num-all-screens').text(screenCount.toLocaleString(locale));

        const selectedScreenCount = $visibleListingItems.find('.screen-select-checkbox:checked').length;
        $('.num-selected-screens').text(selectedScreenCount.toLocaleString(locale));
    }

    function parseDateToIndex(dateStr) {
        const startDate = parseDate($startInput.val());
        const stopDate = parseDate($stopInput.val());
        const date = parseDate(dateStr);

        if (!startDate || !stopDate || !date) {
            return null; // Invalid date
        }

        if (date < startDate) {
            return -1; // Before range
        }

        return Math.floor((date - startDate) / (24 * 3600 * 1000));
    }

    function getClipsFromDs(dsElement, maxSlots) {
        const parts = dsElement.textContent.trim().split(',');
        const clipsPerSlot = parseInt(parts[0], 10) || 0;
        const slotsAvailable = parseInt(parts[1], 10) || 0;
        return clipsPerSlot * Math.min(slotsAvailable, maxSlots);
    }

    const updateAllAvailableSlotsCount = debounce(function() {
        let totalForAllScreens = 0;
        let isIncomplete = false;

        const startIndex = parseDateToIndex($startInput.val());
        const stopIndex = parseDateToIndex($stopInput.val());

        if (startIndex === null || stopIndex === null) {
            $('.num-all-spots').text('0');
            $('.num-all-spots-plus').toggle(false);
            return;
        }

        const $visibleListingItems = $('tr.listing-item:not(.d-none)');

        $visibleListingItems.each(function() {
            const $listingItem = $(this);
            const slotsFromInput = parseInt($listingItem.find('.slots').val(), 10) || 0;
            const $nextNoTopBorder = $listingItem.next('.no-top-border');
            if ($nextNoTopBorder.length === 0) return;

            const $dsElements = $nextNoTopBorder.find('.ds');

            for (let i = startIndex; i <= stopIndex; i++) {
                const dsElement = $dsElements.eq(i)[0];
                if (dsElement) {
                    totalForAllScreens += getClipsFromDs(dsElement, slotsFromInput);
                } else {
                    isIncomplete = true;
                }
            }
        });

        $('.num-all-spots').text(totalForAllScreens.toLocaleString(locale));
        $('.num-all-spots-plus').toggle(isIncomplete);
    }, 200);

    const updateAllChosenSlotsCount = debounce(function() {
        let totalForAllScreens = 0;
        let isIncomplete = false;
        const $selectedListingItems = $('tr.listing-item').has('.screen-select-checkbox:checked');

        $selectedListingItems.each(function() {
            const $listingItem = $(this);
            const startDateIndex = parseDateToIndex($listingItem.find('.start.date').val());
            const stopDateIndex = parseDateToIndex($listingItem.find('.stop.date').val());

            if (startDateIndex === null || stopDateIndex === null) {
                return;
            }

            const slotsFromInput = parseInt($listingItem.find('.slots').val(), 10) || 0;
            const $nextNoTopBorder = $listingItem.next('.no-top-border');
            if ($nextNoTopBorder.length === 0) return;

            const $dsElements = $nextNoTopBorder.find('.ds');

            for (let i = startDateIndex; i <= stopDateIndex; i++) {
                const dsElement = $dsElements.eq(i)[0];
                if (dsElement) {
                    totalForAllScreens += getClipsFromDs(dsElement, slotsFromInput);
                } else {
                    isIncomplete = true;
                }
            }
        });

        $('.num-selected-spots').text(totalForAllScreens.toLocaleString(locale));
        $('.num-selected-spots-plus').toggle(isIncomplete);
    }, 200);

    $(document).on('change', '.start.date, .stop.date, .showing-start.date, .showing-stop.date', function () {
        const $changedInput = $(this);
        const $parent = $changedInput.closest('.listing-item, .showing-row');
        let $startInputElem, $stopInputElem;

        // Determine whether we are dealing with index screens or showings
        if ($changedInput.hasClass('start') || $changedInput.hasClass('showing-start')) {
            $startInputElem = $changedInput;
            $stopInputElem = $parent.find('.stop.date, .showing-stop.date');
        } else {
            $stopInputElem = $changedInput;
            $startInputElem = $parent.find('.start.date, .showing-start.date');
        }

        const startDate = parseDate($startInputElem.val());
        const stopDate = parseDate($stopInputElem.val());

        if ($changedInput.is($startInputElem)) {
            if (!stopDate || stopDate < startDate) {
                $stopInputElem.val(formatDate(startDate));
            }
        } else {
            if (!startDate || startDate > stopDate) {
                $startInputElem.val(formatDate(stopDate));
            }
        }
    });

    $screenSelectListing.on('load.screen-select update.listpress', function () {
        updateScreenCount();
        updateAllAvailableSlotsCount();
        updateAllChosenSlotsCount();

        // ## date fields for each screen
        $('.start.date, .stop.date').off('change').on('change', function() {
            updateAllChosenSlotsCount();
        });

        // ## screen select checkbox
        $('.screen-select-checkbox-all').off('click').on('click', function () {
            const isChecked = this.checked;
            $('.screen-select-checkbox:visible').prop('checked', isChecked);
            $screenSelectSubmit.prop('disabled', $('.screen-select-checkbox:checked').length < 1);
            updateScreenCount();
            updateAllAvailableSlotsCount();
            updateAllChosenSlotsCount();
        });

        // Handle individual screen checkbox changes
        $('.screen-select-checkbox').off('click.update.screen-select').on('click.update.screen-select', function () {
            $screenSelectSubmit.prop('disabled', $('.screen-select-checkbox:checked').length < 1);
            updateScreenCount();
            updateAllChosenSlotsCount();
        }).trigger('update.screen-select');

        $('.exact-time').on('click update.screen-select', function () {
            $(this).closest('tr').find('.slots').toggle(!this.checked);
            $(this).closest('tr').find('.time').toggle(this.checked);
            if (!this.checked) $(this).closest('tr').find('.time').val("");
        }).trigger('update.screen-select');

        // Update post data and slot counts on input changes
        $screenSelectListing.find('select, input').off('change').on('change', function () {
            updatePostData();
            updateAllAvailableSlotsCount();
            updateAllChosenSlotsCount();
        });

    }).trigger('load.screen-select');

    updatePostData();
});
