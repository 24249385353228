// User clicks on Exportovat button and we decide here if hidden form with IDs of postings will be sent or
// button_link clicked which will export all of the rendered postings
$(document).ready(function() {
    $('#export-posting-button').on('click', function(e) {
        e.preventDefault();

        const selectedIds = $('input[name="postings[]"]:checked').map(function() {
            return this.value;
        }).get();

        if (selectedIds.length > 0) {
            $('#selected_posting_ids').val(selectedIds.join(','));
            $('#export_checked_postings_form').submit();
        } else {
            $('#export-posting-listing')[0].click();
        }
    });

    // Mass checkbox select
    $('#select-all-checkbox').on('change', function() {
        const isChecked = $(this).is(':checked');
        $('input[type="checkbox"][id^="postings_"]').prop('checked', isChecked);
    });

    // Start the fetch when
    $('#export-posting-listing').on('click', function(event) {
        event.preventDefault();
        const currentParams = new URLSearchParams(window.location.search);
        const exportUrl = new URL($(this).attr('href'), window.location.origin);
        exportUrl.search = currentParams.toString();
        $(this).attr('href', exportUrl.toString());
        window.location.href = $(this).attr('href');
    });
});
