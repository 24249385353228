$(document).ready(function() {
    $('#export-screen-listing').on('click', function(event) {
        event.preventDefault();
        const currentParams = new URLSearchParams(window.location.search);
        const exportUrl = new URL($(this).attr('href'), window.location.origin);
        exportUrl.search = currentParams.toString();
        $(this).attr('href', exportUrl.toString());
        window.location.href = $(this).attr('href');
    });
});
